import { AutoPlayCarousel } from '@/shadcn-ui/components/ui/auto-play-carousel';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../../shadcn-ui/components/ui/card';

interface ThreeFeaturesProps {
  title: string;
  subtitle: string;
  features: {
    reverse?: boolean;
    component: JSX.Element[];
    title: string;
    description: string;
  }[];
}

export function ThreeFeaturesSection({
  title,
  subtitle,
  features,
}: ThreeFeaturesProps) {
  return (
    <div className="flex w-full items-center justify-center bg-[#F9FAFB]">
      <div className="w-full flex flex-col gap-8 max-w-7xl py-12  px-4 items-center justify-center ">
        <div className="max-w-6xl flex flex-col items-center justify-center gap-2">
          <p className="md:text-5xl text-4xl font-bold text-center text-gray-700 mb-2">
            {title}
          </p>
          <p className="text-2xl text-center">{subtitle}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <Card key={index} className="border-none shadow-md">
              <CardHeader className="p-0">
                <div
                  className={
                    'relative flex h-[500px] w-full flex-col p-6 overflow-hidden '
                  }
                >
                  <AutoPlayCarousel
                    vertical
                    className="[--duration:20s]"
                    reverse={feature.reverse}
                  >
                    {feature.component}
                  </AutoPlayCarousel>
                </div>
              </CardHeader>
              <CardContent className="p-6">
                <CardTitle className="text-xl mb-2">{feature.title}</CardTitle>
                <p className="text-muted-foreground">{feature.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}
