import { ShadCnButton } from '@/shadcn-ui/components/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/shadcn-ui/components/ui/carousel';
import { Input } from '@/shadcn-ui/components/ui/input';
import { ShineBorder } from '@/shadcn-ui/components/ui/shine-border';
import Autoplay from 'embla-carousel-autoplay';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef } from 'react';

interface LandingPageDefaultSectionProps {
  textOrientation: 'left' | 'right';
  title: string;
  titleWithGradient: string;
  subtitle: string;
  cta: string;
  emailPlaceholder: boolean;
}

const images = [
  '/prints/envio-em-massa.png',
  '/prints/fluxo-campanha.png',
  '/prints/rastreio-pedido.png',
  '/prints/atendimento.png',
];
export function LandingPageZenderSection({
  textOrientation,
  title,
  titleWithGradient,
  subtitle,
  cta,
  emailPlaceholder,
}: LandingPageDefaultSectionProps) {
  const { locale } = useRouter();

  const plugin = useRef(Autoplay({ delay: 3000, stopOnInteraction: false }));
  return (
    <div
      className={`flex flex-col items-center justify-center w-full ${
        emailPlaceholder ? 'bg-white' : 'bg-[#F9FAFB]'
      }`}
    >
      <div
        className={`w-full flex flex-col items-center max-w-7xl gap-8 py-12 px-4  ${
          textOrientation === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'
        }`}
      >
        <div className="w-full md:w-1/2 flex flex-col gap-4 md:gap-6 items-start justify-center">
          <div className="text-3xl md:text-4xl font-bold text-gray-700">
            {title}&nbsp;
            <span className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF]  text-transparent bg-clip-text">
              {titleWithGradient}
            </span>
          </div>
          <p className="text-gray-500 text-xl md:text-2xl">{subtitle}</p>
          {emailPlaceholder ? (
            <div className=" items-center gap-4 hidden md:flex">
              <ShineBorder
                className="text-center text-2xl capitalize p-0 shadow-md h-12 w-full"
                color={['#7903EF', '#1C45D6']}
              >
                <Input
                  className="h-11 border-[0px] text-lg w-full "
                  type="email"
                  placeholder={
                    locale === 'pt' ? 'E-mail profissional' : 'Work e-mail'
                  }
                />
              </ShineBorder>
              <ShadCnButton
                className="md:flex hidden text-lg h-12 shadow-lg"
                variant="adstart"
              >
                <Link href={'/trial-adstart-messages'}>{cta}</Link>
              </ShadCnButton>
            </div>
          ) : (
            <ShadCnButton
              variant="link"
              className="text-lg md:text-xl font-semibold px-0"
            >
              <p className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF] text-transparent bg-clip-text">
                <Link href={'/solutions/adstart-messages'}>{cta}</Link>
              </p>
            </ShadCnButton>
          )}
        </div>
        <Carousel
          plugins={[plugin.current]}
          className="w-full md:w-1/2 h-fit flex items-center justify-center"
          onMouseEnter={() => plugin.current.stop()}
          onMouseLeave={() => plugin.current.play()}
          opts={{ loop: true }}
        >
          <CarouselContent>
            {images.map((image, index) => (
              <CarouselItem className="h-full w-full" key={index}>
                <img src={image} alt={title} className="rounded-md " />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  );
}
