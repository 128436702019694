import { AutoPlayCarousel } from '@/shadcn-ui/components/ui/auto-play-carousel';
import { statisticsTranslations } from '@/utils/landingPageTranslations/statisticsTranslations';
import {
  Banknote,
  HandCoins,
  LucideProps,
  ShoppingBag,
  ShoppingCart,
} from 'lucide-react';
import { useRouter } from 'next/router';
import {
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useState,
} from 'react';
import { parseValueToType } from '../../../utils/parseValueToType';
import { UpperHeadingAndTitle } from '../UpperHeadingAndTitle';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return isMobile;
}

export function Statistics() {
  const { locale } = useRouter();
  const isMobile = useIsMobile();

  const statisticsSectionTranslations = statisticsTranslations(locale);

  const statsValues: {
    title: string;
    stat: string;
    isMonetary: boolean;
    icon: ForwardRefExoticComponent<
      Omit<LucideProps, 'ref'> & RefAttributes<SVGSVGElement>
    >;
  }[] = [
    {
      title: statisticsSectionTranslations.revenue,
      stat: parseValueToType.integer(9000000000),
      isMonetary: true,
      icon: Banknote,
    },
    {
      title: statisticsSectionTranslations.investment,
      stat: parseValueToType.integer(2000000000),
      isMonetary: true,
      icon: HandCoins,
    },
    {
      title: statisticsSectionTranslations.orders,
      stat: parseValueToType.integer(23000000),
      isMonetary: false,
      icon: ShoppingBag,
    },
    {
      title: statisticsSectionTranslations.ecoms,
      stat: parseValueToType.integer(1700),
      isMonetary: false,
      icon: ShoppingCart,
    },
    {
      title: statisticsSectionTranslations.ads,
      stat: parseValueToType.integer(2000000),
      isMonetary: false,
      icon: ShoppingCart,
    },
  ];

  return (
    <div className="flex flex-col gap-6 w-full items-center max-w-7xl py-12 px-4 ">
      <UpperHeadingAndTitle
        upperHeading={statisticsSectionTranslations.upperHeading}
        title={statisticsSectionTranslations.title}
      />

      <div className="w-full max-w-6xl gap-4">
        {isMobile ? (
          <div className="flex w-full">
            <AutoPlayCarousel reverse className="[--duration:30s]">
              {statsValues.map((values, index) => (
                <div
                  key={index}
                  className="flex flex-col shadow-md border-2 border-slate-200 rounded-md p-4 w-full justify-center items-start bg-white"
                >
                  <h1 className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF] text-transparent bg-clip-text font-semibold text-2xl sm:text-3xl">
                    +{values.isMonetary && <>R$&nbsp;</>}
                    {values.stat}
                  </h1>
                  <p className="truncate text-lg sm:text-xl font-semibold">
                    {values.title}
                  </p>
                </div>
              ))}
            </AutoPlayCarousel>
          </div>
        ) : (
          <div className="grid grid-cols-5 gap-4">
            {statsValues.map((values, index) => (
              <div
                key={index}
                className="flex flex-col shadow-md border-2 border-slate-200 rounded-md p-4 w-full justify-center items-start bg-white"
              >
                <h1 className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF] text-transparent bg-clip-text font-semibold text-2xl sm:text-3xl">
                  +{values.isMonetary && <>R$&nbsp;</>}
                  {values.stat}
                </h1>
                <p className="truncate text-lg sm:text-xl font-semibold">
                  {values.title}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
