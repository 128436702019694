import { createTranslations } from '../../utils/i18n';

interface Fields {
  header: string;
  faqItems: Array<{
    question: string;
    answer: string;
  }>;
}

export const faqSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        header: 'Frequently Asked Questions',
        faqItems: [
          {
            question:
              'What resources does your platform offer to manage multiple e-commerce clients?',
            answer:
              'Our platform offers a centralized dashboard to manage multiple e-commerce clients, including bulk product updates, cross-store inventory management, consolidated reports, and customizable access levels for clients. This allows agencies to manage multiple stores from a single interface.',
          },
          {
            question:
              'How does the platform help improve marketing campaign efficiency?',
            answer:
              'The platform provides advanced analytics and reporting tools that allow you to monitor and optimize marketing campaigns in real-time, helping to identify the most effective strategies and adjust campaigns for better results.',
          },
          {
            question:
              'Is it possible to integrate the platform with other e-commerce tools?',
            answer:
              'Yes, our platform offers integrations with various popular e-commerce tools such as Shopify, WooCommerce, and Magento, enabling more integrated and efficient management of your online business.',
          },
          {
            question: 'What support options are available for platform users?',
            answer:
              "We offer 24/7 support via chat, email, and phone, plus a comprehensive knowledge base and video tutorials to help users solve any issues and make the most of the platform's features.",
          },
          {
            question:
              'Is the platform secure for storing sensitive customer data?',
            answer:
              'Yes, data security is a priority for us. We use end-to-end encryption and follow security best practices to ensure that all customer information is protected against unauthorized access.',
          },
        ],
      },
      pt: {
        header: 'Perguntas Frequentes',
        faqItems: [
          {
            question:
              'Quais recursos sua plataforma oferece para gerenciar vários clientes de e-commerce?',
            answer:
              'Nossa plataforma oferece um painel centralizado para gerenciar vários clientes de e-commerce, incluindo atualizações em massa de produtos, gerenciamento de inventário entre lojas, relatórios consolidados e níveis de acesso personalizáveis para clientes. Isso permite que as agências gerenciem várias lojas de uma única interface.',
          },
          {
            question:
              'Como a plataforma ajuda a melhorar a eficiência das campanhas de marketing?',
            answer:
              'A plataforma fornece ferramentas avançadas de análise e relatórios que permitem monitorar e otimizar campanhas de marketing em tempo real, ajudando a identificar as estratégias mais eficazes e a ajustar as campanhas para obter melhores resultados.',
          },
          {
            question:
              'É possível integrar a plataforma com outras ferramentas de e-commerce?',
            answer:
              'Sim, nossa plataforma oferece integrações com diversas ferramentas de e-commerce populares, como Shopify, WooCommerce e Magento, permitindo uma gestão mais integrada e eficiente dos seus negócios online.',
          },
          {
            question:
              'Quais são as opções de suporte disponíveis para os usuários da plataforma?',
            answer:
              'Oferecemos suporte 24/7 via chat, e-mail e telefone, além de uma base de conhecimento abrangente e tutoriais em vídeo para ajudar os usuários a resolverem quaisquer problemas e a aproveitarem ao máximo os recursos da plataforma.',
          },
          {
            question:
              'A plataforma é segura para armazenar dados sensíveis dos clientes?',
            answer:
              'Sim, a segurança dos dados é uma prioridade para nós. Utilizamos criptografia de ponta a ponta e seguimos as melhores práticas de segurança para garantir que todas as informações dos clientes estejam protegidas contra acessos não autorizados.',
          },
        ],
      },
    },
  });
