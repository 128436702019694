import { createTranslations } from '../../utils/i18n';

interface Fields {
  title: string;
  upperHeading: string;
  benefits: {
    zeroCost: {
      title: string;
      description: string;
    };
    noFine: {
      title: string;
      description: string;
    };
    noLoyalty: {
      title: string;
      description: string;
    };
    onboarding: {
      title: string;
      description: string;
    };
    templates: {
      title: string;
      description: string;
    };
  };
}

export const benefitsSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        title: 'Start using in minutes and cancel whenever you want',
        upperHeading: 'No headache',
        benefits: {
          zeroCost: {
            title: 'ZERO COST',
            description: 'implementation',
          },
          noFine: {
            title: 'NO FINE',
            description: 'for cancellation',
          },
          noLoyalty: {
            title: 'NO LOYALTY',
            description: 'contract',
          },
          onboarding: {
            title: 'ONBOARDING',
            description: 'in 10 minutes',
          },
          templates: {
            title: 'TEMPLATES',
            description: 'ready to use',
          },
        },
      },
      pt: {
        title: 'Começe a usar em minutos e cancele quando quiser',
        upperHeading: 'Sem dor de cabeça',
        benefits: {
          zeroCost: {
            title: 'CUSTO ZERO',
            description: 'de implementação',
          },
          noFine: {
            title: 'SEM MULTA',
            description: 'recisória',
          },
          noLoyalty: {
            title: 'SEM FIDELIDADE',
            description: 'de contrato',
          },
          onboarding: {
            title: 'ONBOARDING',
            description: 'em 10 minutos',
          },
          templates: {
            title: 'TEMPLATES',
            description: 'para sair usando',
          },
        },
      },
    },
  });
