import { createTranslations } from '../../utils/i18n';

interface Fields {
  upperHeading: string;
  title: string;
  revenue: string;
  investment: string;
  orders: string;
  ecoms: string;
  ads: string;
}

export const statisticsTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        upperHeading: 'Our Numbers',
        title: 'Come grow with us!',
        revenue: 'Revenue',
        investment: 'Investment',
        orders: 'Orders',
        ecoms: 'E-commerces',
        ads: 'Ads',
      },
      pt: {
        upperHeading: 'Nossos Números',
        title: 'Venha crescer com a gente!',
        revenue: 'Faturamento',
        investment: 'Investimento',
        orders: 'Pedidos',
        ecoms: 'E-commerces',
        ads: 'Anúncios',
      },
    },
  });
