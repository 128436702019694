import { Benefits } from '@/components/Landing/Benefits';
import { FAQSection } from '@/components/Landing/FAQSection';
import { HeroWithTwoImages } from '@/components/Landing/HeroWIthTwoImages';
import { Integrations } from '@/components/Landing/Integrations';
import { LandingPageZenderSection } from '@/components/Landing/LandingPageZenderSection';
import { Statistics } from '@/components/Landing/Statistics';
import { Testemonials } from '@/components/Landing/Testemonials';
import { ThreeFeaturesSection } from '@/components/Landing/ThreeFeaturesSection';
import { adstartMessagesKnowMoreTranslations } from '@/utils/landingPageTranslations/adstartMessagesKnowMoreTranslations';
import { adstartMessagesTranslations } from '@/utils/landingPageTranslations/adstartMessagesTranslations';
import { benefitsSectionTranslations } from '@/utils/landingPageTranslations/benefitsSectionTranslations';
import { defaultSectionTranslations } from '@/utils/landingPageTranslations/defaultSectionTranslations';
import { faqSectionTranslations } from '@/utils/landingPageTranslations/faqSectionTranslations';
import { heroTwoImagesTranslations } from '@/utils/landingPageTranslations/heroWithTwoImagesTranslations';
import { integrationsTranslations } from '@/utils/landingPageTranslations/integrationsTranslations';
import { testimonialsTranslations } from '@/utils/landingPageTranslations/testimonialsTranslations';
import { threeFeaturesSectionTranslations } from '@/utils/landingPageTranslations/threeFeaturesSectionTranslations';
import {
  DollarSign,
  FileX,
  FolderSync,
  LayoutTemplate,
  ReceiptText,
} from 'lucide-react';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Base } from '../components/Landing/Base';
import KnowMore from '../components/Landing/KnowMore';
import { LandingPageDefaultSection } from '../components/Landing/LandingPageDefaultSection';
import { trackUserEvent } from '../utils/trackUserEvent';

export interface LandingPageServerSideProps {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

export const getServerSideProps: GetServerSideProps<
  LandingPageServerSideProps
> = async (ctx) => {
  const { query } = ctx;
  const utm_source = query?.utm_source as string;
  const utm_medium = query?.utm_medium as string;
  const utm_campaign = query?.utm_campaign as string;
  const utm_content = query?.utm_content as string;
  await trackUserEvent({
    ctx,
  });

  if (
    ctx.resolvedUrl === '/' &&
    ctx.req.headers.host === 'ecommercedashboard.com.br'
  ) {
    return {
      props: {},
      redirect: {
        destination: '/login',
        permanent: false,
      },
    };
  }

  if (utm_source || utm_medium || utm_campaign || utm_content) {
    return {
      props: {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
      },
    };
  }

  return {
    props: {},
  };
};

export default function LandingPage(props: LandingPageServerSideProps) {
  const { locale } = useRouter();

  const heroTranslations = heroTwoImagesTranslations(locale);
  const threeFeaturesTranslations = threeFeaturesSectionTranslations(locale);
  const landingPageDefaultTranslations = defaultSectionTranslations(locale);
  const benefitsTranslations = benefitsSectionTranslations(locale);
  const faqTranslations = faqSectionTranslations(locale);
  const knowMoreTranslations = adstartMessagesKnowMoreTranslations(locale);
  const adstartMessagesSectionTranslations =
    adstartMessagesTranslations(locale);
  const integrationTexts = integrationsTranslations(locale);
  const testimonialsTexts = testimonialsTranslations(locale);

  const performanceMetricsUrls = [
    '/prints/roas-global.png',
    '/prints/total-investido.png',
    '/prints/total-de-vendas.png',
    '/prints/valor-investido-fb.png',
    '/prints/valor-investido-google.png',
  ];
  const retentionMetricsUrls = [
    '/prints/rfm.png',
    '/prints/indice-recompra.png',
    '/prints/cac-total-clientes.png',
    '/prints/ltv.png',
    '/prints/novos-clientes.png',
  ];
  const operationMetricsUrls = [
    '/prints/sku-tabela.png',
    '/prints/tabela-produto.png',
    '/prints/sku-tabela.png',
    '/prints/tabela-produto.png',
    '/prints/sku-tabela.png',
  ];

  return (
    <>
      <Head>
        <title>
          {' '}
          {locale === 'pt'
            ? 'Adstart | Todas as plataformas, uma aba'
            : 'Adstart | Every ad platform, one tab'}
        </title>
        {/* <!-- Primary Meta Tags --> */}
        <meta
          name="title"
          content="Adstart | Todas as plataformas, uma aba"
          lang="pt"
        />
        <meta
          name="description"
          content="A Adstart é uma plataforma de gerenciamento de ads. Ajudamos equipes de marketing simplificando a visualização de dados"
          lang="pt"
        />
        <meta
          name="keywords"
          content="ad, ads, anúncio, anúncios, gerenciamento de ads, gerenciamento de anúncios, plataforma de ads, plataforma de anúncios, cpc, cpp, ctr, cpa, clicks, como aumentar número de clicks, como fazer meu cliente clicar no anúncio, cliente, como diminuir cpc, gastar menos em ads, gastar menos em anúncios, otimizar anúncios, anúncios converterem mais, conversão, aumentar conversão, melhorar anúncios, melhorar ads, ads pagos, facebook, instagram, twitter, google, google ads, facebook ads, anúncios no facebook, otimizar tempo, equipe de marketing, gerenciamento equipe marketing, ad sense, seo, roas, roi, como aumentar meu roi, aumenta a conversão"
          lang="pt"
        />
        <meta
          name="title"
          content="Adstart | Every ad platform, one tab"
          lang="en"
        />
        <meta
          name="description"
          content="Adstart is an ad management platform. We help marketing teams by simplifying data visualization"
          lang="en"
        />
        <meta
          name="keywords"
          content="ad, ads, ad management, ad platform, ads platform, cpc, cpp, ctr, cpa, clicks"
          lang="en"
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" lang="pt" />
        <meta property="og:url" content="https://Adstart.com.br/" lang="pt" />
        <meta
          property="og:title"
          content="Adstart | Todas as plataformas, uma aba"
          lang="pt"
        />
        <meta
          property="og:description"
          content="A Adstart é uma plataforma de gerenciamento de ads. Ajudamos equipes de marketing simplificando a visualização de dados"
          lang="pt"
        />
        <meta property="og:image" content="/logo.svg" lang="pt" />

        <meta property="og:type" content="website" lang="en" />
        <meta property="og:url" content="https://Adstart.com.br/en" lang="en" />
        <meta
          property="og:title"
          content="Adstart | Every ad platform, one tab"
          lang="en"
        />
        <meta
          property="og:description"
          content="Adstart is an ad management platform. We help marketing teams by simplifying data visualization"
          lang="en"
        />
        <meta property="og:image" content="/logo.svg" lang="en" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" lang="pt" />
        <meta
          property="twitter:url"
          content="https://Adstart.com.br/pt"
          lang="pt"
        />
        <meta
          property="twitter:title"
          content="Adstart | Todas as plataformas, uma aba"
          lang="pt"
        />

        <meta
          property="twitter:description"
          content="A Adstart é uma plataforma de gerenciamento de ads. Ajudamos equipes de marketing simplificando a visualização de dados"
          lang="pt"
        />

        <meta property="twitter:image" content="/logo.svg" lang="pt" />

        <meta property="twitter:card" content="summary_large_image" lang="en" />
        <meta
          property="twitter:url"
          content="https://Adstart.com.br/en"
          lang="en"
        />
        <meta
          property="twitter:title"
          content="Adstart | Every ad platform, one tab"
          lang="en"
        />

        <meta
          property="twitter:description"
          content="Adstart is an ad management platform. We help marketing teams by simplifying data visualization"
          lang="en"
        />

        {/* internationalization */}
        <link rel="alternate" href="https://Adstart.com.br/en" hrefLang="en" />
        <link
          rel="alternate"
          href="https://Adstart.com.br/pt"
          hrefLang="pt-br"
        />
        <link
          rel="alternate"
          href="https://Adstart.com.br/en"
          hrefLang="x-default"
        />
      </Head>
      <Base>
        <HeroWithTwoImages
          utm_source={props.utm_source}
          utm_medium={props.utm_medium}
          utm_campaign={props.utm_campaign}
          utm_content={props.utm_content}
          title={heroTranslations.firstSentence}
          titleGradient={heroTranslations.secondSentence}
          subtitle={heroTranslations.subheading}
          cta={heroTranslations.primaryButtonText}
          imageUrl="/prints/dash.png"
        />
        <ThreeFeaturesSection
          title={threeFeaturesTranslations.title}
          subtitle={threeFeaturesTranslations.subtitle}
          features={[
            {
              title: threeFeaturesTranslations.features.performance.title,
              reverse: false,
              description:
                threeFeaturesTranslations.features.performance.description,
              component: performanceMetricsUrls.map((metrics, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex flex-col items-center justify-center"
                  >
                    <img width={510} src={metrics} alt={metrics} />
                  </div>
                );
              }),
            },
            {
              title: threeFeaturesTranslations.features.retention.title,
              reverse: true,
              description:
                threeFeaturesTranslations.features.retention.description,
              component: retentionMetricsUrls.map((metrics, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex flex-col items-center justify-center"
                  >
                    <img width={510} src={metrics} alt={metrics} />
                  </div>
                );
              }),
            },
            {
              title: threeFeaturesTranslations.features.operation.title,
              reverse: false,
              description:
                threeFeaturesTranslations.features.operation.description,
              component: operationMetricsUrls.map((metrics, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex flex-col items-center justify-center"
                  >
                    <img width={510} src={metrics} alt={metrics} />
                  </div>
                );
              }),
            },
          ]}
        />
        <Integrations
          upperHeading={integrationTexts.upperHeading}
          title={integrationTexts.title}
        />

        <LandingPageZenderSection
          cta={adstartMessagesSectionTranslations.cta}
          textOrientation="right"
          title={adstartMessagesSectionTranslations.title}
          titleWithGradient={
            adstartMessagesSectionTranslations.titleWithGradient
          }
          emailPlaceholder={false}
          subtitle={adstartMessagesSectionTranslations.subtitle}
        />
        <Statistics />
        <LandingPageDefaultSection
          cta={landingPageDefaultTranslations.overview.cta}
          imageUrl="/prints/visao-geral.png"
          textOrientation="left"
          title={landingPageDefaultTranslations.overview.title}
          titleWithGradient={
            landingPageDefaultTranslations.overview.titleWithGradient
          }
          subtitle={landingPageDefaultTranslations.overview.subtitle}
          trialLink="/trial"
        />
        <Benefits
          title={benefitsTranslations.title}
          upperHeading={benefitsTranslations.upperHeading}
          benefitsValues={[
            {
              title: benefitsTranslations.benefits.zeroCost.title,
              description: benefitsTranslations.benefits.zeroCost.description,
              icon: DollarSign,
            },
            {
              title: benefitsTranslations.benefits.noFine.title,
              description: benefitsTranslations.benefits.noFine.description,
              icon: FileX,
            },
            {
              title: benefitsTranslations.benefits.noLoyalty.title,
              description: benefitsTranslations.benefits.noLoyalty.description,
              icon: ReceiptText,
            },
            {
              title: benefitsTranslations.benefits.onboarding.title,
              description: benefitsTranslations.benefits.onboarding.description,
              icon: FolderSync,
            },
            {
              title: benefitsTranslations.benefits.templates.title,
              description: benefitsTranslations.benefits.templates.description,
              icon: LayoutTemplate,
            },
          ]}
        />
        <LandingPageDefaultSection
          cta={landingPageDefaultTranslations.goals.cta}
          imageUrl="/prints/goals-page.png"
          title={landingPageDefaultTranslations.goals.title}
          titleWithGradient={
            landingPageDefaultTranslations.goals.titleWithGradient
          }
          subtitle={landingPageDefaultTranslations.goals.subtitle}
          textOrientation="right"
          trialLink="/trial"
        />

        <Testemonials testimonials={testimonialsTexts.testimonials} />
        <FAQSection
          header={faqTranslations.header}
          faqItems={faqTranslations.faqItems}
        />
        <KnowMore
          title={knowMoreTranslations.title}
          upperHeading={knowMoreTranslations.upperHeading}
          cta={knowMoreTranslations.cta}
          trialLink={'/trial'}
        />
      </Base>
    </>
  );
}
