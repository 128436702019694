import { createTranslations } from '../../utils/i18n';

interface Fields {
  testimonials: Array<{
    name: string;
    role: string;
    content: string;
    avatar: string;
  }>;
}

export const testimonialsTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        testimonials: [
          {
            name: 'Leandro Dalponte',
            role: 'CEO - E-traction',
            content:
              'Before Adstart, our traffic managers could only handle 12 e-commerces, now they can handle 18.',
            avatar: '/customers/leandro.jpeg',
          },
          {
            name: 'Lucas Nascimento',
            role: 'E-commerce manager',
            content:
              'With Adstart we can have a clear view of performance results in seconds.',
            avatar: '/customers/lucas-nascimento.jpeg',
          },
        ],
      },
      pt: {
        testimonials: [
          {
            name: 'Leandro Dalponte',
            role: 'CEO - E-traction',
            content:
              'Antes da Adstart, nossos gestores de tráfego conseguiam atender apenas 12 e-commerces, hoje eles conseguem atender 18.',
            avatar: '/customers/leandro.jpeg',
          },
          {
            name: 'Lucas Nascimento',
            role: 'E-commerce manager',
            content:
              'Com a Adstart nós conseguimos ter uma visão clara do resultado de performance em segundos.',
            avatar: '/customers/lucas-nascimento.jpeg',
          },
        ],
      },
    },
  });
