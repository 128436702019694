import { createTranslations } from '../../utils/i18n';

interface Fields {
  overview: {
    title: string;
    titleWithGradient: string;
    subtitle: string;
    cta: string;
  };
  goals: {
    title: string;
    titleWithGradient: string;
    subtitle: string;
    cta: string;
  };
}

export const defaultSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        overview: {
          title: 'Overview and complete support for',
          titleWithGradient: 'multiple e-commerces',
          subtitle: 'Ideal for groups and agencies',
          cta: 'Learn more',
        },
        goals: {
          title: 'Grow safely with',
          titleWithGradient: 'predictability',
          subtitle: 'Track your goals and projections in real time',
          cta: 'Learn more',
        },
      },
      pt: {
        overview: {
          title: 'Visão Geral e suporte completo para',
          titleWithGradient: 'múltiplos e-commerces',
          subtitle: 'Ideal para grupos e agências',
          cta: 'Saiba mais',
        },
        goals: {
          title: 'Cresça com segurança e',
          titleWithGradient: 'previsibilidade',
          subtitle: 'Acompanhe suas metas e projeções em tempo real',
          cta: 'Saiba mais',
        },
      },
    },
  });
