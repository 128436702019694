import { createTranslations } from '../../utils/i18n';

interface Fields {
  upperHeading: string;
  title: string;
}

export const integrationsTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        upperHeading: 'Integrations',
        title: 'Connect with your favorite platforms',
      },
      pt: {
        upperHeading: 'Integrações',
        title: 'Conecte com suas plataformas favoritas',
      },
    },
  });
