import { abbreviateNumber } from './abbreviateNumber';

export type NumberFormats = 'currency' | 'integer' | 'percentage' | 'float';

type ParseValueToType = {
  [key in NumberFormats]: (
    value: number,
    locale?: string,
    shouldAbbreviate?: boolean,
  ) => string;
};

export const parseValueToType: ParseValueToType = {
  float: (value: number, locale = 'pt-BR') => {
    return value.toLocaleString(locale, {
      maximumFractionDigits: 2,
    });
  },

  integer: (value: number, locale = 'pt-BR', shouldAbbreviate = true) => {
    if (shouldAbbreviate) {
      return abbreviateNumber(+value.toFixed(0), 1).toString();
    }

    return value.toLocaleString(locale, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  },
  percentage: (value: number, locale = 'pt-BR', shouldAbbreviate = true) => {
    if (shouldAbbreviate) {
      return value.toLocaleString(locale, {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    }

    return value.toLocaleString(locale, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
  currency: (value: number, locale = 'pt-BR', shouldAbbreviate = true) => {
    if (shouldAbbreviate) {
      const currencyFromLocale: { [locale: string]: string } = {
        en: '$',
        'en-US': '$',
        pt: 'R$ ',
        'pt-BR': 'R$ ',
      };

      const currency = currencyFromLocale[locale || 'en'];
      const valueAsNumber = value || 0;
      const abbreviateValue = abbreviateNumber(+valueAsNumber.toFixed(2), 1);

      return `${currency}${abbreviateValue}`;
    }

    const parser = {
      pt: {
        locale: 'pt-BR',
        currency: 'BRL',
      },
      en: {
        locale: 'en',
        currency: 'USD',
      },
    };

    const data =
      locale === 'en' || locale === 'pt' ? parser[locale] : parser.en;

    return (value || 0).toLocaleString(data.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: data.currency,
    });
  },
};
