import { createTranslations } from '../../utils/i18n';

interface Fields {
  title: string;
  subtitle: string;
  features: {
    performance: {
      title: string;
      description: string;
    };
    retention: {
      title: string;
      description: string;
    };
    operation: {
      title: string;
      description: string;
    };
  };
}

export const threeFeaturesSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        title: 'Your e-commerce copilot',
        subtitle: 'Grow safely with clarity about your operation',
        features: {
          performance: {
            title: 'Performance',
            description: 'Channels, ads, SEO and CRO',
          },
          retention: {
            title: 'Retention',
            description: 'RFM Analysis, repurchase, CAC and LTV',
          },
          operation: {
            title: 'Operation',
            description:
              'ABC Curve, inventory control, output projection and replenishment needs',
          },
        },
      },
      pt: {
        title: 'O copiloto do seu e-commerce',
        subtitle: 'Cresça com segurança tendo clareza sobre sua operação',
        features: {
          performance: {
            title: 'Performance',
            description: 'Canais, anúncios, SEO e CRO',
          },
          retention: {
            title: 'Retenção',
            description: 'Análise RFM, recompra, CAC e LTV',
          },
          operation: {
            title: 'Operação',
            description:
              'Curva ABC, controle de estoque, projeção de saída e necessidade de reposição',
          },
        },
      },
    },
  });
