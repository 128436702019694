import { createTranslations } from '../../utils/i18n';

interface Fields {
  firstSentence: string;
  secondSentence: string;
  subheading: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}

export const heroTwoImagesTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        firstSentence: 'All your e-commerce data in',
        secondSentence: 'one screen',
        subheading:
          'Save hours of your day by centralizing revenue, inventory, ads, repurchase, projections and more!',
        primaryButtonText: 'Learn more',
        secondaryButtonText: 'Start now',
      },
      pt: {
        firstSentence: 'Todos os dados do seu e-commerce em',
        secondSentence: 'uma tela',
        subheading:
          'Economize horas do seu dia centralizando faturamento, estoque, ads, recompra, projeções e muito mais!',
        primaryButtonText: 'Saiba mais',
        secondaryButtonText: 'Começar agora',
      },
    },
  });
