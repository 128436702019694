import { createTranslations } from '../i18n';

interface Fields {
  title: string;
  titleWithGradient: string;
  subtitle: string;
  cta: string;
}

export const adstartMessagesTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        title: 'Adstart Messages,',
        titleWithGradient: 'the only WhatsApp tool your e-commerce needs',
        subtitle:
          'Your entire operation in one chip. Mass messaging, cart and order recovery, order status and customer service in a single tool',
        cta: 'Discover Adstart Messages',
      },
      pt: {
        title: 'Adstart Messages,',
        titleWithGradient:
          'a única ferramenta de WhatsApp que seu e-commerce precisa',
        subtitle:
          'Toda a sua operação em um chip. Disparos em massa, recuperação de carrinhos e pedidos, status de pedido e atendimento em uma única ferramenta',
        cta: 'Conhecer Adstart Messages',
      },
    },
  });
